.image-control {
  width: 280px;
  height: 180px;
  border-radius: 10px;
}

.parent-container {
  background-color: whitesmoke;
  padding: 25px;
  box-shadow: 20px 20px wheat;
  border-radius: 20px;
}

.img-control {
  height: 198px;
}
/* 
.button3-control {
  margin: 75px 0px 0px 0px;
} */
