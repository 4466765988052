.navbar {
  display: flex;
  flex: start;
  align-items: center;
  position: absolute;
}
.nav-bar {
  display: flex;
  cursor: pointer;
  padding-top: 20px;
  margin-bottom: 10px;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 50px;
}

@media (min-width: 801px) {
  .nav-bar {
    display: none;
  }
}

svg {
  vertical-align: middle;
  position: relative;
  max-width: 100%;
}

svg.menu-svg {
  color: white;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: white;
}

.nav-menu {
  background-color: white;
  width: 200px;
  height: fit-content;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 1;
}

.nav-text {
  display: flex;
  flex: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  color: black;
  text-decoration: none;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #afe272;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  background-color: #afe272;
  width: 100%;
  height: 50px;
  display: flex;
  flex: start;
  align-items: center;
}

.sidenav-container {
  display: flex;
  padding: 10px 10px 0px 10px;
  justify-content: flex-end;
}
