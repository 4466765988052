.live-background-container {
  position: relative;
}

.live-background-container::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url("../../images/ShowPage/show_pageLandscape.jpg");
  background-size: cover;
  opacity: 0.6;
  border-top-width: 0.5px;
}

.subtitle5,
.programme-info-control {
  position: relative;
}

/*Section Style*/
.section-child-container {
  border-bottom-style: inset;
  justify-content: center;
}
.section-list-style-control {
  list-style: none;
  padding: 0;
}

.co-creator-control {
  padding: 10px;
}
.cast-list-control {
  padding: 10px;
}

.text-color-green {
  color: #82d121;
  text-shadow: 0 0 black;
}

.text-color-orange {
  color: #ff9f00;
  text-shadow: 0 0 black;
}

.text-color-blue {
  color: #00c1a2;

  text-shadow: 0 0 black;
}

.text-color-pink {
  color: #ff2f69;

  text-shadow: 0 0 black;
}

/* Show Copy */

.text-border-control {
  margin: 20px 20px 20px 20px;
  text-align: justify;
  padding: 5%;
  border-style: solid;
  border-width: thin;
}
