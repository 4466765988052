/*TEAM Background-START*/
.home-container-video2 {
  padding: 10px 0px 50px 0px;
  position: relative;
}
.home-container-video2::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url(../../images/TEAM/YGeginNTW27.jpg);
  background-size: cover;
  opacity: 0.5;
}
/*TEAM Background-END HERE*/

/*NTW Page background START HERE*/
.home-container-video2a {
  padding: 10px 0px 50px 0px;
  position: relative;
}
.home-container-video2a::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url(../../images/TEAM/TideWhisperer3.jpg);
  background-size: cover;
  opacity: 0.5;
  /* background-image: url("../../images/TEAM/TideTEAMSocial.jpg"); */
}
/*NTW Background-END HERE*/

/*Add media query for mobile background position:fixed and text font white here*/

video,
.video-container,
.info-container,
.heading-control,
.video-about-text-control,
.other-text,
.translate-button,
.translate-button-control {
  position: relative;
}

/*Background-END*/

/*Video Container-START*/
.video-about-text-control {
  display: flex;
  justify-content: center;
}

.team-subtitle {
  font-size: 20px;
  text-align: left;
  margin: 0px 0px 15px 15px;
}
/*Video Container-END*/

.title-team-ntw-subtitle {
  text-align: left;
  margin: 20px 0px 0px 30px;
}
#ntw-title {
  color: #82d121;

  text-shadow: 0 0 black;
}
#team-title {
  color: white;

  text-shadow: 0 0 black;
}

.info-container {
  padding: 30px;
}

.team-info-container {
  padding: 30px;
  text-align: left;
}

/*TEAM UL-START*/
.list-items {
  list-style: circle;
}

.list-items-parent {
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: left;
  position: relative;
}
.list-image-control {
  display: flex;
  flex-wrap: wrap;
}

.list-image-container {
  display: flex;
  align-content: space-evenly;
  max-width: 100%;
  flex-wrap: wrap;
  margin: 0px 0px 0px 30px;
}

.list-items {
  position: relative;
}
/*TEAM UL-END*/

.join-team-other-text-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0px 0px 100px 0px;
  float: left;
}

/*SPACING Container*/
section.spacing-container {
  padding: 50px 50px 50px 50px;
}

.list-show-none {
  list-style: none;
}

/*NTW Page main Text line-height*/
.article-line-control {
  line-height: 2;
  text-align: left;
  padding: 5%;
}

@media (max-width: 801px) {
  .article-line-control {
    text-align: center;
    padding: 5%;
  }
}

.other-text {
  line-height: 2;
  text-align: initial;
}

/*IMAGES-START*/

.image-team {
  max-width: 100%;
  width: 350px;
  height: 276.63px;
  margin: 10px;
}
@media (max-width: 801px) {
  .image-team {
    max-width: 100%;
    width: 350px;
    height: auto;
    margin: 10px;
  }
}
.ntw-logo-about {
  width: 300px;
  height: auto;
}
/*IMAGES-END*/

/*Subscribe button*/
.subscribe-terms {
  margin: 20px 0px 0px 0px;
}

.subscribe-text-call-to-action {
  padding: 0px 50px 0px 50px;
  text-align: initial;
}

@media (max-width: 801px) {
  .subscribe-text-call-to-action {
    padding: 0px;
  }
}

/*Sign up*/
.sign-up-control {
  text-align: center;
  margin: 0px 0px 50px 0px;
}
