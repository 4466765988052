h1.subtitle1 {
  color: white;
  font-family: hero-new, sans-serif;
}

.access-content-control {
  border-radius: 5px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  padding: 5%;
  margin: 5%;
  overflow: scroll;
  height: 100vh;
}
