/*Header */

@media (max-width: 802px) {
  #nav-header {
    display: none;
  }
}

#nav-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  padding: 10px 10px 5px 10px;
}

.nav-links {
  text-decoration: none;
  height: 25px;
}

li {
  color: #3e455a;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.nav-link-control {
  list-style: none;
}

.bee-logo-control {
  width: 35px;
  height: auto;
}
