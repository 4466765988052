body {
  margin: 0;
  text-align: center;
  font-family: hero-new, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.contact_heading {
  font-size: 36px;
}

* {
  box-sizing: border-box;
}

/* */
/*Form Details in fullscreen mode*/

.form-content-parent {
  margin: 85px 10px 50px 15px;
  text-align: left;
}

.form-sizing-control {
  margin: 25px 0px 0px 0px;
  text-align: left;
  background-color: white;
  border-radius: 10px;
  width: fit-content;
}

@media (min-width: 801px) {
  .form-sizing-control {
    padding: 5px 25px 5px 0px;
  }
}

.form-title-control {
  margin: 25px 0px 0px 0px;
}
.subtitle3 {
  padding: 0px 0px 15px 0px;
}

/*@media queries for Mobile Device/Ipads*/
@media (max-width: 801px) {
  .form-content-parent {
    text-align: center;
    margin: 70px 10px 0px 0px;
  }
  @media (max-width: 801px) {
    .subtitle3 {
      margin: 0px 0px 0px 25px;
    }
  }
}
.anchor-control {
  color: blue;
  word-break: break-all;
}

/*Contact Form Control*/
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  margin-top: 20px;
}

.form-control {
  margin-bottom: 10px;
  padding: 0px;
}

.form-parent {
  margin: 0px 15px 0px 15px;
}

select {
  font-size: large;
}

textarea,
#message {
  font-size: large;
  height: 200px;
  text-align: start;
  font-size: initial;
}
