.map-parent-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.map-control {
  height: 300px;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
}

.map-container-control-spacebetween {
  padding: 40px 0px 0px 0px;
}

.text-control-maps {
  text-align: center;
  color: white;
}

.map-parent-container {
  padding: 10px 0px 50px 0px;
  position: relative;
}
