/*CONNECT(Stories) PAGE HERE*/
.story-container {
  padding: 40px 0px 0px 0px;
  margin-bottom: 50vh;
}

.connect-button-control {
  padding: 20px 0px 0px 0px;
  list-style: none;
}

.video-container-parent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#white-font {
  color: whitesmoke;
}

.blue-font {
  color: #00c1a2;
}

.story-blurbs,
.story-subtitle {
  color: white;
  text-align: center;
}

.story-blurbs {
  padding: 0px 5px 0px 5px;
}

.audio-container-control {
  text-align: center;
  padding: 0;
  margin: auto;
}
@media (min-width: 802px) {
  .audio-container-control {
    padding: 0;
  }
}

.video-text-control {
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: relative;
  padding: 0px 0px 20px 0px;
}
@media (max-width: 802px) {
  .video-text-control {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
  }
}
@media (min-width: 802px) {
  .video-text-control {
    padding: 20px 150px 20px 150px;
    text-align: left;
  }
}

.story-parent-container {
  margin: 30px 10px 30px 20px;
}

#story-parent-container1 {
  margin: 30px 10px 30px 20px;
}

.story-children-blurbs,
.title-text {
  /* padding: 0px 20px 0px 20px; */
  color: white;
}

/*Blurb background color*/
.story-info-container {
  padding: 10px 10px 10px 10px;
  color: white;
}

/*LISTEN(Music) PAGE HERE*/
.title-text {
  font-size: initial;
}

.title-text-container {
  padding: 15px 0px 50px 0px;
}

.audio-container-parent {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

.home-footer {
  padding: 0px 20px 0px 20px;
}

video,
.subtitle1,
.title-text,
.audio-container-control,
.story-container,
.story-blurbs,
.story-subtitle,
.story-info-container,
.story-children-blurbs,
.home-footer {
  position: relative;
}

.intro-text-control {
  margin: 0px 0px 45px 0px;
  color: black;
}

.intro-text-container {
  padding: 0px 15px 0px 15px;
}
