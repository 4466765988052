.extra-height {
  height: 100vh;
}

h3.story-subtitle {
  color: white;
}

div.story-info-container {
  padding: 10px 150px 0px 150px;
  color: white;
}

@media (max-width: 802px) {
  div.story-info-container {
    margin: 10% 0% 2% 0%;
    padding: 10px 10px 10px 10px;
    color: white;
  }
}
