.socials-control {
  padding: 20px 0px 0px 0px;
}

/*fb/contacts/terms/privacy section*/
.footer-ul-control {
  display: flex;

  justify-content: space-evenly;
  padding-left: 0rem;
  list-style: none;
}

@media (max-width: 820px) {
  .footer-ul-control {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 0rem;
    list-style: none;
  }
}

@media (max-width: 820px) {
  li.extra-spacing {
    margin: 15px 0px 15px 0px;
  }
}

.Link-control {
  font-size: 16px;
  color: #3e455a;
}

/*Social Media handler control */
.social-media-control {
  padding: 0px 20px 0px 20px;
  word-wrap: break-word;
  color: #3e455a;
}
.socials-link-control {
  list-style: none;
}

/*Charity Logos*/
.charity-logo {
  width: 188px;
  height: auto;
}

.acw-logo {
  width: 250px;
  height: auto;
}
.charity-logo,
.acw-logo {
  max-width: 100%;
}
