body {
  margin: 0%;
  font-family: hero-new, sans-serif;
}

/*Background Transition*/
.nav-container {
  animation: fadeIn 2s;
}

.middle-nav-list {
  animation: fadeIn 15s;
}

/* Middle button nav-START*/
.nav-container {
  position: relative;
  margin: 0px 50px 0px 50px;
  padding: 10px 50px 0px 50px;
  height: 100vh;
  display: flex;
  justify-content: center;
}

@media (min-device-width: 320px) and (max-device-height: 500px) {
  .nav-container {
    height: fit-content;
  }
}

.nav-container::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  opacity: 0.6;
}
/* Middle button nav-END*/
.sidenav-container,
.subtitle1,
.subtitle2,
.middle-nav-list,
h2,
.trigger-pop-up,
.translate-button2 {
  position: relative;
}

.nav-child {
  font-size: 20px;
}

.nav-links {
  padding: 5px 0px 0px 0px;
}

.welcome {
  font-family: hero-new, sans-serif;
  font: bold;
  padding: 35px;
  font-size: 30px;
  display: inline-block;
  color: black;
}

/*Transition elements-START*/

.welcome {
  animation: transitionIn 2s;
}

.subtitle1,
.subtitle2,
.subtitle5 {
  animation: transitionIn 8s;
}

.card-control {
  animation: transitionIn 8.5s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
/*Transition elements-END*/
.features-container {
  z-index: 0;
  margin: 0px;
  padding: 0px 0px 0px 36px;
  position: relative;
  list-style-type: none;
  overflow: scroll hidden;
  display: flex;
  width: 100%;
}

.subtitle1 {
  display: flex;
  margin: 30px 0px 30px 0px;
  padding: 60px 20px 10px 20px;
}

.subtitle2 {
  display: -webkit-box;
  margin: 30px 10px 30px 0px;
  padding: 50px 0px 0px 20px;
}

a,
.nav-button-list,
li {
  list-style: none;
  text-decoration: none;
}

.nav-button-list {
  margin: 0px 0px 10px 0px;
}

/*Middle Discover nav-START*/

.default-button {
  width: 200px;
  height: 100px;
  font-family: hero-new, sans-serif;
  font-size: 22px;
  color: white;
  border-radius: 50%;
  border: none;
}

.default-button .btn-primary {
  border: none;
}

@media (max-width: 801px) {
  .default-button {
    height: 80px;
  }
}

/* Bee Image about button*/

.nav-list-child {
  display: inline-flex;
  margin-bottom: 15%;
}
.middle-nav-list {
  padding: 0;
}

.nav-container-control {
  padding: 50px;
}

#simple-button {
  background-color: #00c1a2;
}

#green-button {
  background-color: #82d121;
}

#blue-button {
  background-color: #89d7e8;
}

#pink-button {
  background-color: #ff2f69;
}
/*Middle Discover nav-END*/
