.simplepage-container {
  background-color: white;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.prompt-text {
  padding-top: 50px;
}

video,
.video-container,
.info-container,
.flex-container,
.child-container,
.challenge-text,
.challenge-text2,
.text-container,
#background-simple-acts {
  position: relative;
}

/* Simple Act images*/

.flex-container {
  border: 5px solid white;
  border-style: hidden;

  flex-wrap: wrap;
}

.child-container {
  border: 5px solid white;
  border-style: hidden;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  padding: 25px;
}

.child2-container {
  border: 5px solid white;
  border-style: hidden;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  padding: 10px;
}

.simple-acts-container-parent {
  display: flex;
  flex-flow: wrap;
  max-width: 100%;
  justify-content: space-evenly;
  position: relative;
  padding: 0px 0px 50px 0px;
}
.simple-acts-container-parent,
.simple-acts-video,
.simplepage-container {
  animation: fadeIn 10s;
}

.simple-acts-video {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

article.challenge-text {
  text-align: justify;
}

.text-container {
  margin: 50px;
}

.text-video-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.text-video-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

article.challenge-text2,
.challenge-text2 b,
article.challenge-text1 {
  text-align: justify;
  color: white;
}

@media (min-width: 801px) {
  article.challenge-text2 {
    margin: 0px 0px 0px 20px;

    padding: 0px 20px 0px 20px;
  }
}
@media (min-width: 801px) {
  .challenge-text1 {
    margin: 0px 20px 0px 0px;
    padding: 0px 20px 0px 20px;
  }
}

@media (max-width: 801px) {
  .text-video-column {
    max-width: 100%;
    flex: none;
    text-align: center;
  }
  article.challenge-text2 {
    margin: 0;
  }
}

#background-simple-acts {
  padding: 0px 0px 350px 0px;
}
