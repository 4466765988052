body {
  margin: 0;

  font-size: 20px;
}

.terms-page {
  height: fit-content;
  padding: 5px 5px 5px 5px;
  position: relative;
}

.terms-page::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url("../../images/Background-placeholders/pencil.jpg");
  background-color: burlywood;
  opacity: 0.55;
}

.terms-content-control,
.header-control,
.access-content-control,
.form-control-parent,
.subtitle3,
.form-sizing-control,
.form-title-control,
.contact-location-info {
  position: relative;
}

.header-control,
.terms-content-control {
  text-align: left;
  letter-spacing: 1.1px;
}
.header-control {
  font-size: 20px;
}

.terms-content-control {
  font-size: 15px;
  word-wrap: break-word;
}

.anchor-control {
  word-wrap: break-word;
}
