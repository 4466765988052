body {
  margin: 0;
  font-family: hero-new, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
}
