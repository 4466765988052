body {
  margin: 0;
}
/*SA Prompt Page generic*/

.prompt-text {
  color: #3e455a;
  font-size: 30px;
  padding: 100px 20px 10px 20px;
}

.charity-name {
  color: #ff2f69;
}
.charity-name2 {
  color: #ff2f69;
}

.charity-name3 {
  color: #ff9f00;
}

/* All Page sizing & Background-colors*/

.challenge-page {
  padding: 34px 24px 0px;
}

.image {
  margin: 5px;
  width: 120px;
  height: 120px;
}

.image-container {
  padding: 24px;
}

.place-holder {
  max-width: 100%;
  height: auto;
  width: 400px;
}

.simple-acts {
  padding: 50px;
}
/*Background*/
.challenge-page {
  padding: 10px 0px 50px 0px;
  position: relative;
}

.challenge-page::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url(../../../images/SAboards/3boardlandscape-optimal.jpg);
  background-size: cover;
  opacity: 0.7;
  border-top-style: double;
  border-color: grey;
  border-top-width: 0.5px;
  height: auto;
}

.prompt-text,
.act-control-child,
.act-control-container,
.act-control-child,
.image-container,
.intro-text {
  position: relative;
}

/*Challenge Item layout control */

.flex-parent {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  border-bottom-style: inset;
}

@media (max-width: 802px) {
  .flex-parent {
    flex-wrap: wrap;
  }
}

.flex-child {
  margin: 20px 0px 0px 0px;
}

.flex-child-image {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.article-image-container {
  padding: 50px 5px 30px 5px;
  margin: 5px 5px 25px 5px;
}

.procession-image-container {
  text-align: center;
  margin: 75px;
}

@media (max-width: 801px) {
  .procession-image-container {
    margin: 20px;
    text-align: center;
    border: 1px solid #c3c3c3;
    flex-wrap: wrap;
    align-content: center;
    padding: 15px 0px 15px 0px;
  }
}

.procession-image {
  max-width: 100%;
  width: 200px;
  height: auto;
}

.intro-text {
  padding: 0px 0px 30px 0px;
  background-color: white;
  border-radius: 10px;
  opacity: 0.9;
}

@media (min-width: 801px) {
  .intro-text {
    margin: 0px 60px;
  }
}

/*act control transitionIn (from homepage.css)*/
.act-control-child {
  animation: transitionIn 25s;
  color: #3e455a;
}

.text-control3 {
  text-align: justify;
  color: #3e455a;
}

.text2-subheading {
  text-align: left;
  display: block;
  color: #3e455a;
}
.text-control,
.discover-subheading,
.resources-subheading,
.quote-heading {
  padding: 0px 30px;
  text-align: left;
  display: block;
  color: #3e455a;
}

.prompt-act-container {
  padding-bottom: 90px;
}

@media (max-width: 801px) {
  .prompt-act-container {
    padding-bottom: 0px;
  }
}

@media (max-width: 801px) {
  .prompt-text {
    padding: 20px 20px 10px 20px;
  }
}

.quote-heading {
  padding: 0px 30px;
}

.welldone-message {
  padding: 25px 50px;
}
