.background-explore-image {
  position: static;
}
div.background-explore-image::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  height: 200px;
  background-position: center;
  opacity: 0.85;
  background-color: #82d121;
}

/* Devices */
@media (min-width: 767px) {
  div.card-row {
    margin: 250px 0px 0px 0px;
  }
}

div.row {
  margin: 200px 0px;
}

@media (min-width: 1024px) and (min-height: 1366px) {
  div.row {
    margin: 250px 0px 250px 0px;
  }
}

.translate-button-control .home-footer,
ul,
header {
  position: relative;
}
