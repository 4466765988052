div.background-bee-image::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url(../../images/Background-placeholders/bee_lowopacity-optimized.png);
  background-size: cover;
  height: 110vh;
  background-position: center;
}
.background-bee-image {
  position: static;
}

.translate-button,
.translate-button2,
.translate-button-control .home-footer,
ul,
header,
.welcome-text-control {
  position: relative;
}

/*COLOR FOR EACH BEE BACKGROUND PAGE*/

.brown {
  background-color: #db5a00;
}
.dark-green {
  background-color: #008476;
}

.orange {
  background-color: #ffa301;
}

.pink {
  background-color: #ff4b6d;
}
.green {
  background-color: #acd356;
}

/*NTW Logo */
.ntw-main {
  max-width: 100%;
  width: 100px;
  height: auto;
  float: right;
  padding: 10px 10px 0px 0px;
}

/*Bee Logo*/
.main-bee-logo {
  max-width: 100%;
}

.main-bee-logo {
  border-radius: 10%;
  width: 300px;
  height: auto;
  box-shadow: 5px 5px whitesmoke;
}

div.beeimage-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* .card-container {
  margin: 100px 0px 0px 0px;
} */

/* .card {
  height: 100%;
} */

.translate-button-control {
  color: white;
  display: flex;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
  /*   temp need to move into nav tag for semantics*/
}

h2 {
  text-align: center;
  color: white;
  /* margin: 0px 0px 50px 0px;
  padding: 60px 10px 0px 10px; */
  text-align: center;
  display: flex;
  justify-content: center;
}
