.audio-style-control {
  --primary: #ff4b6d;
  --secondary: #ffa301;

  position: relative;

  max-width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: hero-new, sans-serif;
  height: fit-content;

  margin: 15px 0px 15px 0px;
}

.audio-icons-control {
  width: auto;
  height: auto;
  max-width: 100%;
  background: none;
  border: none;
}
/* controls hover state */

.audio-icons-control:hover {
  color: var(--primary);
}

.play-pause,
.forward-back {
  color: var(--secondary);
}
